/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Book.ttf");
  font-weight: 400;
}

/************ Default Css Variables ************/
:root {
  --bgColBlack: #121212;
  --bgColWhite: #fff;
  --borderCol: rgba(255, 255, 255, 0.5);
  --bannerImgUrl: url("./assets/img/banner-bg.png");
  --altbannerImgUrl: url("./assets/img/color-sharp2.png");
  --bannerImg: var(--bannerImgUrl);
  --pColor: #b8b8b8;
  --mainPurple: #aa367c;
  --mainBlue: #4a2fbd;
  --altPurple: rgba(170, 54, 124, 0.5);
  --altBlue: rgba(74, 47, 189, 0.5);

  --defaultBgGrad: linear-gradient(90.21deg,
      var(--mainPurple) -5.91%,
      var(--mainBlue) 111.58%);
  --altBgGrad: linear-gradient(90.21deg,
      var(--altPurple) -5.91%,
      var(--altBlue) 111.58%);
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  height: 100%;
  background-color: var(--bgColBlack) !important;
  color: var(--bgColWhite) !important;
  font-family: "Centra", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.hover-effect:hover {
  transform: translateY(-3px);
  transition: 0.3s ease-in-out;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid var(--borderCol);
  border-radius: 20px;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

p.danger {
  color: red;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid var(--borderCol);
  border-radius: 20px;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.under-line {
  height: 3px !important;
  width: 30%;
  position: relative;
  left: 35%;
}

/************ Darkmode Css ************/
.checkbox-label {
  width: 100px;
  height: 42px;
  position: relative;
  display: block;
  background: #ebebeb;
  border-radius: 20px;
  box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4),
    inset 0px -5px 15px rgba(255, 255, 255, 0.4);
  cursor: pointer;
}

.checkbox-label:after {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  top: 1px;
  left: 1px;
  background: linear-gradient(180deg, #ffcc89, #d8860b);
  border-radius: 18px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.checkbox-input {
  width: 0;
  height: 0;
  visibility: hidden;
}

.checkbox-input:checked+.checkbox-label {
  background: #242424;
}

.checkbox-input:checked+.checkbox-label:after {
  left: 99px;
  transform: translateX(-100%);
  background: linear-gradient(180deg, #777, #3a3a3a);
}

.checkbox-label,
.checkbox-label:after {
  transition: 0.5s;
}

.checkbox-label:active:after {
  width: 26px;
}

.checkbox-input:checked+.checkbox-label+.checkbox-background {
  background: #242424;
}

.checkbox-sun {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 100;
  fill: var(--bgColWhite);
  transition: 0.5s;
}

.checkbox-moon {
  width: 40px;
  height: 40px;
  top: 1px;
  position: absolute;
  z-index: 100;
  left: 62px;
  fill: #7e7e7e;
  transition: 0.5s;
}

.checkbox-input:checked+.checkbox-sun {
  fill: #7e7e7e;
}

.checkbox-input:checked+.checkbox-moon {
  fill: var(--bgColWhite);
}

/************ Logo Css ************/
.logo {
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.6), 2px 2px 0 rgba(0, 0, 0, 0.5),
    3px 3px 0 rgba(0, 0, 0, 0.4), 4px 4px 0 rgba(0, 0, 0, 0.3),
    5px 5px 0 rgba(0, 0, 0, 0.2), 5px 5px 0 rgba(0, 0, 0, 0.1);
}

/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: var(--bgColBlack);
  border-bottom: 1px double var(--borderCol);
}

nav.navbar a.navbar-brand {
  width: 20%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: var(--bgColWhite) !important;
  letter-spacing: 0.8px;
  padding: 0 2vw;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
  text-decoration: underline;
}

span.navbar-text {
  display: flex;
  align-items: center;
  gap: 1vw;
  padding: 1vw 0;
}

.social-icon {
  display: flex;
}

.social-icon a {
  color: red !important;
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin: 10px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid var(--borderCol);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: var(--bgColWhite);
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 65%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: invert(1) hue-rotate(360deg);
}

.social-icon a:hover {
  transform: translateY(-3px);
  transition: 0.3s ease-in-out;
}

.navbar-text button {
  font-weight: 700;
  color: var(--bgColWhite);
  border: 1px solid var(--bgColWhite);
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 15px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  border-radius: 5px;
  background-color: var(--bgColWhite);
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: var(--bgColBlack);
  border: 1px solid var(--bgColBlack);
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;

}

.navbar-toggler {
  border: 2px solid var(--borderCol) !important;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid var(--bgColWhite);
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid var(--bgColWhite);
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: var(--bgColWhite);
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: var(--bgColWhite);
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: var(--bgColWhite);
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 10px 0 100px 0;
  background-image: var(--bannerImg);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  color: var(--bgColWhite);
  background: var(--altBgGrad);
  border: 1px solid var(--borderCol);
  border-radius: 5px;
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  min-height: 400px;
}

.banner p {
  color: var(--pColor);
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner button {
  color: var(--bgColWhite);
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  margin-left: 25px;
}

.banner img {
  animation: updown 3s linear infinite;
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(-20px);
  }
}

.txt-rotate>.wrap {
  border-right: 0.08em solid #666;
}

/************ About Css ************/
.about {
  padding: 0 0 50px 0;
  position: relative;
}

.about-img {
  width: 50%;
}

.about-progress {
  height: 2vw !important;
}

.about-progress-title {
  color: var(--bgColWhite) !important;
  width: 25%;
  background-color: var(--altPurple) !important;
}

.about-progress-bar {
  background-color: var(--altPurple) !important;
  color: var(--bgColWhite) !important;
}

.about-bx {
  background: var(--bgColBlack);
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}

.about h2 {
  font-size: 45px;
  font-weight: 700;
}

.about p {
  color: var(--pColor);
  font-size: 16px;
  letter-spacing: normal;
  line-height: 1.5em;
  margin: 0;
}

.about .label p {
  font-size: 16px;
  margin: 0;
  text-align: left;
}

.about .label h2,
.about .label h5 {
  margin: 0;
}

.about-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.about-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}

.background-image-left {
  top: -30%;
  height: 150%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}


.skills ul {
  list-style-type: none;
  display: flex;
  gap: 2%;
  flex-wrap: wrap;
  flex-direction: row;
  background: linear-gradient(180deg, var(--mainPurple), var(--mainBlue));
  padding: 5%;
  border-radius: 10%;
  justify-content: space-around;

}

.skills ul li img {
  height: 100px;
  width: 100px;
  box-sizing: border-box;
}

.skills ul li h2 {
  font-size: 20px;
  border-style: double;
  border-right: none;
  border-left: none;
}

.skills ul li {
  gap: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}


/************ Projects Css ************/
.project {
  padding: 50px 0;
  position: relative;
}

.project-box {
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
  background-color: var(--bgColBlack);
}

.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.project p {
  color: var(--pColor);
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}

.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}

.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: var(--bgColWhite);
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}

.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: var(--defaultBgGrad);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.project .nav.nav-pills .nav-link.active {
  border: 1px solid var(--bgColWhite);
}

.nav-link#projects-tabs-tab-first {
  border: 1px solid var(--borderCol);
  border-radius: 55px 0px 0px 55px;
}

.nav-link#projects-tabs-tab-second {
  border-top: 1px solid var(--borderCol);
  border-bottom: 1px solid var(--borderCol);
}

.nav-link#projects-tabs-tab-third {
  border: 1px solid var(--borderCol);
  border-radius: 0 55px 55px 0;
}

.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}

.proj-imgbx img {
  width: 100%;
  height: auto;
  min-height: 280px;
}

.project .row {
  justify-content: center !important;
}

.proj-imgbx::before {
  content: "";
  background: var(--defaultBgGrad);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 100%;
}

.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 900;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.background-image-right {
  top: -30%;
  position: absolute;
  bottom: 0;
  height: 150%;
  width: 35%;
  right: 0;
  z-index: -4;
}

.learn-more {
  color: var(--bgColWhite) !important;
  margin-top: 15px !important;
  font-weight: 900;
}

/************ Contact Css ************/
.contact {
  background: var(--defaultBgGrad);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0;
}

.contact img {
  width: 92%;
}

.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid var(--borderCol);
  border-radius: 20px;
  color: var(--bgColWhite);
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  background: var(--bgColWhite);
  color: var(--bgColBlack);
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: var(--bgColWhite);
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: var(--bgColBlack);
  opacity: 0.8;
}

.contact form button {
  font-weight: 700;
  color: var(--bgColBlack);
  background-color: var(--bgColWhite);
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 5px;
  border-color: var(--bgColBlack);
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: var(--bgColWhite);
  border-color: var(--bgColWhite);
  transform: translateY(-3px);
  transition: 0.3s ease-in-out;
}

.contact form button::before {
  content: "";
  background: var(--bgColBlack);
  width: 0;
  height: 100%;
  position: absolute;
  border-radius: 5px;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contact form button:hover::before {
  width: 100%;
}

.download {
  display: flex;
  justify-content: center;
}

.download a {
  margin: 25px 0;
}

.download button {
  font-weight: 700;
  color: var(--bgColBlack);
  background-color: var(--bgColWhite);
  padding: 14px 48px;
  font-size: 18px;
  border-radius: 5px;
  position: relative;
  transition: 0.3s ease-in-out;
  box-shadow: 3px 3px 10px var(--borderCol);
  border: 2px solid var(--bgColWhite);
}

.download button::before {
  content: "";
  background: var(--bgColBlack);
  width: 0;
  height: 100%;
  position: absolute;
  border-radius: 5px;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.download button:hover::before {
  width: 100%;
}

.download button span {
  z-index: 1;
  position: relative;
}

.download button:hover {
  color: var(--bgColWhite);
  border: 2px solid var(--bgColWhite);
  transform: translateY(-3px);
  transition: 0.3s ease-in-out;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  background: var(--defaultBgGrad);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.up-arrow {
  display: none !important;
  width: 50px !important;
  padding: 0 !important;
}

.up-arrow img {
  animation: updown 1s linear infinite !important;
  width: 100% !important;
}

.footer img {
  width: 50%;
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  color: var(--bgColWhite);
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.text-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/************ Modal Css ************/

.btn-primary a {
  color: var(--bgColWhite) !important;
  text-decoration: none !important;

}

.btn-secondary {
  color: var(--bgColWhite) !important;
  text-decoration: none !important;
  background-color: var(--altPurple) !important;
  border-color: var(--altPurple) !important;

}

.btn-primary {
  background-color: var(--altBlue) !important;
  border-color: var(--altBlue) !important;
}



.btn-primary a:hover {
  color: var(--bgColBlack) !important;
}

.btn-primary:hover {
  background-color: var(--altPurple) !important;
  border-color: var(--altPurple) !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2) !important;
  transform: translateY(-10%) !important;
}


.btn-secondary:hover {
  background-color: var(--altBlue) !important;
  border-color: var(--altBlue) !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2) !important;
  transform: translateY(-10%) !important;
  color: var(--bgColBlack) !important;
}

.custom-modal-container {
  top: 7vh;
}

.custom-modal-header {
  height: 45vh;
  color: var(--bgColBlack);
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
}

.custom-modal-header img {
  object-fit: scale-down;
}

.custom-modal-title {
  width: 100%;
  color: green;
}

.custom-modal-body {
  color: var(--bgColWhite);
  background-color: var(--bgColBlack);
}

.custom-modal-footer {
  color: var(--bgColWhite);
  background-color: var(--bgColBlack);
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: var(--pColor);
  border-radius: 15px;
}

/************ Welcome Css ************/
.welcome-container {
  min-width: 100%;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  z-index: 999999999;
  position: absolute;
  opacity: 1;
}

.welcome-wrapper {
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;

}

.fade-eff {
  opacity: 0;
  transform: translateX(300px);
  transition: all 250ms linear 1s;
}







/************ Mobile Css ************/

@media (max-width: 768px) {
  .fade-eff {
    transform: translateY(300px);
  }

  .about-bx {
    padding: 60px 30px;
  }

  .col-sm-6 {
    width: 100% !important;
  }

  span.navbar-text {
    flex-direction: column;
  }

  .navbar-nav {
    align-items: center;
  }

  .col-6 {
    width: 100% !important;
  }

  nav.navbar a.navbar-brand {
    width: 35%;
  }

  .footer img {
    width: 75%;
  }

  .skills ul li img {
    height: 50px;
    width: 50px;
  }

  .footer .align-items-center {
    gap: 10px;
    align-items: center !important;

  }

  .footer-logo {
    text-align: center;
  }

  .proj-imgbx img {
    min-height: 170px;
  }

  .up-arrow {
    display: block !important;
  }

  #darkmode,
  #resume {
    display: none;
  }

  .under-line {
    width: 80%;
    left: 10%;
  }

  .learn-more {
    margin-top: 5px !important;

  }
}